import React, { useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Box, Button, Flex } from 'rebass/styled-components';
import { Label, Input } from '@rebass/forms/styled-components';
import Select from 'react-select';
import Text from './text';
import styled, { ThemeProvider } from 'styled-components';
import {
  addDays,
  format,
  isSameDay,
  isBefore,
  parse,
  isValid,
  getYear,
  getMonth,
  startOfMonth,
  addMonths,
  endOfMonth,
} from 'date-fns';
import {
  Availability,
  BookingPlatform,
  CalendarCustomHeader,
  getDaysOfMonth,
  getServiceStartDate,
  months,
  Service,
  ServiceAvailabilityApiResponse,
  ServiceNamesLookup,
  SubService,
} from '@avicennapharmacy/managemymeds-shared';
import ReCAPTCHA from 'react-google-recaptcha';
import { avicennaTheme } from '../theme/GlobalStyle';
import { ServicesContext } from '../contexts/ServicesContext';
import { customStyles } from '../theme/customSelectStyles';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ClapSpinner } from 'react-spinners-kit';
import { Pharmacy } from '../types/Pharmacy';
import { ApiResponse } from '../types/ApiResponse';
import { subServiceIdMap } from './shared';

const StyledInput = styled(Input)`
  background-color: white;
  border-color: hsl(0, 0%, 80%);
  font-family: 'Gotham-Light';
  color: ${avicennaTheme.colors.primaryLight};
  ::placeholder {
    color: ${avicennaTheme.colors.primaryLight};
  }
`;

const StyledInlineInput = styled(StyledInput)`
  display: inline;
  margin-right: 10px;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  color: ${avicennaTheme.colors.primary};
  font-family: 'Gotham-Light';
  font-size: 16px;
  border: none;
  background-color: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  ::placeholder {
    color: ${avicennaTheme.colors.primaryLight};
  }
`;

type BorderWrapperProps = {
  padding?: boolean;
};

const BorderWrapper = styled.div<BorderWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 2px solid ${avicennaTheme.colors.primaryLight};
  border-radius: 8px;
  box-sizing: border-box;

  ${(props) => props.padding && 'padding: 10px;'}

  ${avicennaTheme.breakpoints.mobileTablet} {
    width: 100%;
  }
  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
  }
`;

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const contactNumRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

type inputProps = {
  labelColour: string | string[] | null;
  platform?: string | string[] | null;
  initialService?: string | null;
};

const getAddressPart = (line: string | null) => (line ? `, ${line}` : '');

const mapPharmacyToSelectValue = (p: Pharmacy) => ({
  value: p,
  label: p.name,
  address: `${p.address.address1}${getAddressPart(p.address.address2)}${getAddressPart(
    p.address.address3,
  )}${getAddressPart(p.address.city)}${getAddressPart(p.address.postcode)}`,
});

const mapServiceToSelectValue = (s: Service, serviceNamesLookup: ServiceNamesLookup | null) => ({
  value: s.serviceNameId,
  label: serviceNamesLookup && s?.serviceNameId ? serviceNamesLookup[s?.serviceNameId].name[0] : '',
});

const mapServiceSubTypeToSelectValue = (ss: SubService, serviceNamesLookup: ServiceNamesLookup | null, serviceNameId: string | null) => ({
  value: ss.serviceNameId,
  label: serviceNameId && serviceNamesLookup ? (serviceNamesLookup[serviceNameId]?.subs[ss.serviceNameId].name ?? '') : '',
});

const Services = ({ labelColour, initialService }: inputProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [serviceNamesLookup, setServiceNamesLookup] = useState<ServiceNamesLookup | null>(null);
  const [availability, setAvailability] = useState<Availability>({});
  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);
  const [service, setService] = useState<Service | null>(null);
  const [serviceHasSubTypes, setServiceHasSubTypes] = useState<boolean | null>(null);
  const [serviceSubType, setServiceSubType] = useState<SubService | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [start, setStart] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNum, setContactNum] = useState('');
  const [recaptchaPass, setRecaptchaPass] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dd, setDd] = useState<string>('');
  const [mm, setMm] = useState<string>('');
  const [yyyy, setYyyy] = useState<string>('');
  const [valid, setValid] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { selectedPharmacyApiKey, selectedPharmacyId } = useContext<any>(ServicesContext);
  const [hasAvailability, setHasAvailability] = useState(false);
  const [lastLoadedMonth, setLastLoadedMonth] = useState<Date | null>(null);
  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);

  const apiHeader = {
    headers: {
      'x-api-key': selectedPharmacyApiKey,
    },
  };

  labelColour = labelColour ?? 'black';

  useEffect(() => {
    const getPharmacies = async () => {
      try {
        setLoading(true);
        const res = await axios.post<any, AxiosResponse<ApiResponse<Pharmacy[]>>>(
          `${process.env.GATSBY_BOOKING_SERVICE_URL!}/listowners`,
          {},
          apiHeader,
        );

        setService(null);
        setDate(null);
        setStart(null);
        setPharmacies(res.data.result);
        const matchingPharmacies = res.data.result.filter((p) => p.externalId === selectedPharmacyId);

        if (matchingPharmacies.length === 1) {
          setPharmacy(matchingPharmacies[0]);
          setError('');
        } else if (selectedPharmacyId !== '' && matchingPharmacies.length === 0) {
          setPharmacy(null);
          setPharmacies([]);
          setError('This pharmacy does not currently take service bookings - please select another');
        }
      } catch {
        setError('Failed to load pharmacies');
      } finally {
        setLoading(false);
      }
    };
    getPharmacies();
  }, [selectedPharmacyId]);

  useEffect(() => {
    if (initialService && !initialised && services.length) {
      const selectedService = services.filter((service) => initialService === service.rowKey);

      setService(selectedService[0] ?? null);
      setDate(null);
      setStart(null);
      setInitialised(true);
    }
  }, [services]);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoading(true);

        const { data: serviceNames } = await axios.post(`${process.env.GATSBY_BOOKING_SERVICE_URL!}/listservicenames`, {
          ownerId: pharmacy?.externalId,
          ExternalId: pharmacy?.externalId,
        }, apiHeader);

        setServiceNamesLookup(serviceNames?.serviceNamesLookup);

        const res = await axios.post<any, AxiosResponse<ApiResponse<Service[]>>>(
          `${process.env.GATSBY_BOOKING_SERVICE_URL!}/listservices`,
          { externalId: pharmacy?.externalId },
          apiHeader,
        );
        setServices(res.data.result);
        setError('');
      } catch {
        setError('Failed to load services');
      } finally {
        setLoading(false);
      }
    };

    if (pharmacy) {
      getServices();
    }
  }, [pharmacy]);

  const getAvailability = async (dateFrom: Date) => {
    try {
      setLoading(true);

      if (lastLoadedMonth && isBefore(new Date(dateFrom), endOfMonth(lastLoadedMonth))) {
        setLoading(false);
        return;
      }

      const daysOfMonth = getDaysOfMonth(new Date(dateFrom));

      setDisabledDates(daysOfMonth);

      const res = await axios.post<any, AxiosResponse<ServiceAvailabilityApiResponse>>(
        `${process.env.GATSBY_BOOKING_SERVICE_URL!}/availability`,
        {
          ownerId: pharmacy?.externalId,
          resourceId: service?.resourceId,
          serviceId: service?.rowKey,
          dateFrom: dateFrom,
          dateTo: endOfMonth(new Date(dateFrom)),
        },
        apiHeader,
      );

      res.data.days.map(({ availableDate }) => {
        const index = daysOfMonth.findIndex((x) => isSameDay(x, new Date(availableDate)));
        if (index !== -1) {
          daysOfMonth.splice(index, 1);
        }
      });

      availability[`${getMonth(dateFrom)}${getYear(dateFrom)}`] = {
        availability: res.data.result,
        days: res.data.days,
        unavailableDates: daysOfMonth,
      };

      unavailableDates.push(...daysOfMonth);
      setUnavailableDates(unavailableDates);
      setAvailability(availability);

      setLastLoadedMonth(startOfMonth(new Date(dateFrom)));
      if (!hasAvailability) setHasAvailability(!!res.data.days.length);
      setError('');
    } catch {
      setError('Failed to load services');
    } finally {
      setDisabledDates([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLastLoadedMonth(null);
    setAvailability({});
    setHasAvailability(false);
    setUnavailableDates([]);
  }, [service, serviceSubType]);

  const monthChange = (date: Date, compensate: number) => {
    if (service) {
      const serviceStartDate = getServiceStartDate(service, serviceSubType?.serviceNameId ?? null);
      const calendarDate = addMonths(date, compensate);
      const dateFrom = isBefore(calendarDate, serviceStartDate) ? serviceStartDate : startOfMonth(calendarDate);
      setFromDate(dateFrom);
      getAvailability(dateFrom);
    }
  };

  const spinner = (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ClapSpinner aria-busy size={15} frontColor="black" backColor="black" />
    </div>
  );

  const DatePicker = (
    <>
      <BorderWrapper>
        <StyledDatePicker
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }: CalendarCustomHeader) => (
            <>
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  style={{
                    border: 'none',
                    margin: '0px 30px',
                    fontSize: 16,
                    background: 'none',
                  }}
                  onClick={() => {
                    decreaseMonth();
                    monthChange(date, -1);
                  }}
                  type="button"
                  disabled={loading || prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                {months[getMonth(date)] + ' ' + getYear(date)}

                <button
                  style={{
                    border: 'none',
                    margin: '0px 30px',
                    fontSize: 16,
                    background: 'none',
                  }}
                  onClick={() => {
                    increaseMonth();
                    monthChange(date, 1);
                  }}
                  type="button"
                  disabled={loading || nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
              {loading && spinner}
            </>
          )}
          dateFormat="dd/MM/yyyy"
          disabled={!service || (service.hasServiceSubTypes === true && !serviceSubType)}
          placeholderText="Select date..."
          showPopperArrow={false}
          excludeDates={loading ? disabledDates : unavailableDates}
          selected={selectedDate}
          minDate={
            ((service && !service.subServices?.length) || (service && service.subServices?.length && serviceSubType)) ?
              getServiceStartDate(service, serviceSubType?.serviceNameId ?? null)
              : undefined
          }
          maxDate={service?.endDt ? new Date(service.endDt) : undefined}
          onChange={(date: any) => {
            setDate(format(new Date(date), 'yyyy-MM-dd'));
            setStart(null);
            setSelectedDate(date);
          }}
        />
      </BorderWrapper>
    </>
  );

  useEffect(() => {
    if (pharmacy && service && (!service.hasServiceSubTypes || (service?.hasServiceSubTypes && serviceSubType))) {
      setFromDate(getServiceStartDate(service, serviceSubType?.serviceNameId ?? null));
    }
  }, [service, serviceSubType]);

  useEffect(() => {
    if (pharmacy && service) {
      getAvailability(fromDate);
    }
  }, [fromDate]);

  const formSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      if (isValid(parse(`${yyyy}-${mm}-${dd}`, 'yyyy-MM-dd', new Date()))) {
        try {
          setSubmitting(true);
          await axios.post(
            `${process.env.GATSBY_GUEST_BOOKING_SERVICE_URL!}`,
            {
              pharmacyId: selectedPharmacyId,
              externalId: pharmacy?.externalId,
              resourceId: service?.resourceId,
              serviceId: service?.rowKey,
              startDate: start,
              customerId: email,
              firstName: firstName,
              lastName: lastName,
              dateOfBirth: `${yyyy}-${mm}-${dd}`,
              contactNumber: contactNum,
              email,
              platform: BookingPlatform.Landing,
              serviceSubType: serviceSubType?.serviceNameId ? subServiceIdMap(service?.serviceNameId!, serviceSubType?.serviceNameId!, serviceNamesLookup!) : '',
              serviceName: service?.serviceNameId && serviceNamesLookup
                ? serviceNamesLookup[service?.serviceNameId].name[0] : ''
            },
            apiHeader,
          );
          setSuccess(true);
          setError('');
        } catch {
          setError('Failed to complete booking');
        } finally {
          setSubmitting(false);
        }
      } else {
        setError('Invalid date of birth');
        setSubmitting(false);
      }
    }
  };

  const filterAvailableServices = (s: Service) => {
    const filterStartDate = addDays(new Date(), s?.daysBookableInAdvance ? s?.daysBookableInAdvance : 0);
    return s.active && (!s.endDt || isBefore(filterStartDate, new Date(s.endDt)));
  };

  const updateEmail = (emailInput: string) => {
    setEmail(emailInput);
    setValid(emailRegex.test(emailInput));
  };

  const updateContactNum = (contactNumInput: string) => {
    setContactNum(contactNumInput);
    setValid(contactNumRegex.test(contactNumInput));
  };

  let submitDisabled =
    !pharmacy ||
    !service ||
    !date ||
    !start ||
    !recaptchaPass ||
    !firstName ||
    !lastName ||
    !dd ||
    !mm ||
    !yyyy ||
    !email ||
    !contactNum ||
    !valid ||
    (serviceHasSubTypes && !serviceSubType);

  return (
    <ThemeProvider theme={avicennaTheme}>
      <Flex flexWrap="wrap">
        <Box width={1} pr={3}>
          {error.trim() !== '' && (
            <Box width={1} pr={3} backgroundColor="errorLight" mb={3}>
              <Text color="errorDark" p={3}>
                {error}
              </Text>
            </Box>
          )}
          <form onSubmit={async (e: any) => await formSubmit(e)}>
            <Label htmlFor="pharmacy">
              <Text color={labelColour}>Branch</Text>
            </Label>
            <Select
              required
              isSearchable
              styles={customStyles}
              name="pharmacy"
              isLoading={loading}
              isDisabled={true}
              options={pharmacies.map(mapPharmacyToSelectValue)}
              placeholder="Loading Branch..."
              hideSelectedOptions
              filterOption={(option, searchText) =>
                option.label.toLowerCase().includes(searchText.toLowerCase()) ||
                option.data.address.toLowerCase().includes(searchText.toLowerCase())
              }
              isOptionSelected={(option) => option.value === pharmacy}
              onChange={async (value) => {
                setPharmacy(value?.value ?? null);
                setService(null);
                setDate(null);
                setStart(null);
              }}
              value={pharmacy ? mapPharmacyToSelectValue(pharmacy) : null}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            />
            <Label htmlFor="service" mt={3}>
              <Text color={labelColour}>Service</Text>
            </Label>
            <Select
              required
              styles={customStyles}
              isDisabled={!pharmacy}
              isSearchable
              name="service"
              loading={loading}
              options={services.filter((s) => filterAvailableServices(s)).map((x) => mapServiceToSelectValue(x, serviceNamesLookup))}
              placeholder="Select service..."
              hideSelectedOptions
              isOptionSelected={(option) => option.value === service?.serviceNameId}
              formatOptionLabel={({ label }) => label}
              onChange={(option) => {
                const selectedService: Service | null = option?.value ? services.find(x => x.serviceNameId === option?.value) ?? null : null;
                setService(selectedService);
                setSelectedDate(null);
                setDate(null);
                setStart(null);
                setServiceHasSubTypes(selectedService?.hasServiceSubTypes ?? false);
                setServiceSubType(null);
              }}
              value={service ? mapServiceToSelectValue(service, serviceNamesLookup) : null}
            />
            {service?.subServices?.length ? (
              <>
                <Label htmlFor="date" mt={3}>
                  <Text color={labelColour}>{service?.serviceNameId && serviceNamesLookup
                    ? serviceNamesLookup[service?.serviceNameId].name[0]
                    : ''} type</Text>
                </Label>
                <Select
                  value={serviceSubType ? mapServiceSubTypeToSelectValue(serviceSubType, serviceNamesLookup, service?.serviceNameId) : null}
                  required
                  styles={customStyles}
                  isDisabled={!pharmacy}
                  isSearchable
                  name="service"
                  options={service.subServices?.map((ss) => mapServiceSubTypeToSelectValue(ss, serviceNamesLookup, service?.serviceNameId))}
                  placeholder="Select service..."
                  hideSelectedOptions
                  onChange={(option) => {
                    setServiceSubType(service?.subServices?.find(x => x.serviceNameId === option?.value) ?? null);
                    setSelectedDate(null);
                    setDate(null);
                    setStart(null);
                  }}
                />
              </>
            ) : null}
            <Label htmlFor="subtype" mt={3}>
              <Text color={labelColour}>Date</Text>
            </Label>
            {DatePicker}
            <Label htmlFor="time" mt={3}>
              <Text color={labelColour}>Time</Text>
            </Label>
            <Select
              required
              styles={customStyles}
              isDisabled={!date || !selectedDate}
              name="time"
              isLoading={false}
              placeholder="Select time..."
              formatOptionLabel={({ label }) => label}
              hideSelectedOptions
              isOptionSelected={(option) => option.value === start}
              options={
                selectedDate && availability[`${getMonth(selectedDate)}${getYear(selectedDate)}`]
                  ? availability[`${getMonth(selectedDate)}${getYear(selectedDate)}`].availability
                    .filter((x) => date && x.isAvailable && isSameDay(new Date(x.start), new Date(date)))
                    .map((x) => {
                      return {
                        value: x.start,
                        label: format(new Date(x.start), 'HH:mm'),
                      };
                    }, [])
                  : []
              }
              onChange={(value) => {
                setStart(value?.value ?? null);
              }}
              value={start ? { value: start, label: format(new Date(start), 'HH:mm') } : null}
            />
            <Text mt={5} color={labelColour} fontSize={3}>
              Your details
            </Text>
            <Label htmlFor="first" mt={3}>
              <Text color={labelColour}>First name</Text>
            </Label>
            <StyledInput
              required
              name="first"
              placeholder="First name"
              value={firstName}
              onChange={(e: any) => setFirstName(e.target.value)}
            />
            <Label htmlFor="last" mt={3}>
              <Text color={labelColour}>Last name</Text>
            </Label>
            <StyledInput
              required
              name="last"
              placeholder="Last name"
              value={lastName}
              onChange={(e: any) => setLastName(e.target.value)}
            />
            <Label htmlFor="last" mt={3}>
              <Text color={labelColour}>Date of birth</Text>
            </Label>
            <StyledInlineInput
              required
              name="dd"
              type="number"
              min="1"
              max="31"
              placeholder="dd"
              value={dd}
              onChange={(e: any) => setDd(e.target.value)}
              style={{ width: '80px' }}
            />
            <StyledInlineInput
              required
              name="mm"
              placeholder="mm"
              type="number"
              min="1"
              max="12"
              value={mm}
              onChange={(e: any) => setMm(e.target.value)}
              style={{ width: '80px' }}
            />
            <StyledInlineInput
              required
              name="yyyy"
              type="number"
              placeholder="yyyy"
              value={yyyy}
              onChange={(e: any) => setYyyy(e.target.value)}
              style={{ width: '120px' }}
            />
            <Label htmlFor="email" mt={3}>
              <Text color={labelColour}>Contact email</Text>
            </Label>
            <StyledInput
              required
              name="email"
              type="email"
              placeholder="Contact email"
              value={email}
              onChange={(e: any) => updateEmail(e.target.value)}
            />
            <Label htmlFor="number" mt={3}>
              <Text color={labelColour}>Contact number</Text>
            </Label>
            <StyledInput
              required
              name="number"
              type="tel"
              placeholder="Contact number"
              value={contactNum}
              onChange={(e: any) => updateContactNum(e.target.value)}
            />
            <Box mt={4}>
              <ReCAPTCHA
                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY!}
                onChange={() => setRecaptchaPass(true)}
              />
            </Box>
            {success ? (
              <>
                <Text my={4} fontSize={3} color={labelColour}>
                  Booking successful! You will receive a confirmation email shortly.
                </Text>
                <Text mb={1} fontFamily="bold" color={labelColour}>
                  Your appointment:
                </Text>
                <Text color={labelColour}>
                  {service?.serviceNameId && serviceNamesLookup
                    ? serviceNamesLookup[service?.serviceNameId].name[0]
                    : ''}
                  {serviceSubType
                    ? `: ${subServiceIdMap(service?.serviceNameId!, serviceSubType?.serviceNameId!, serviceNamesLookup!)}`
                    : ''}
                </Text>
                <Text color={labelColour}>
                  {format(new Date(date!), 'EEEE do LLLL yyyy')} at {format(new Date(start!), 'HH:mm')}
                </Text>
                <Text color={labelColour}>
                  {`${pharmacy!.name}, ${pharmacy!.address.address1}${getAddressPart(
                    pharmacy!.address.address2,
                  )}${getAddressPart(pharmacy!.address.address3)}${getAddressPart(
                    pharmacy!.address.city,
                  )}${getAddressPart(pharmacy!.address.postcode)}`}
                </Text>
              </>
            ) : (
              <>
                {error.trim() !== '' && (
                  <Box width={1} pr={3} backgroundColor="errorLight" mt={3}>
                    <Text color="errorDark" p={3}>
                      {error}
                    </Text>
                  </Box>
                )}
                <Button
                  variant="secondary"
                  mt={4}
                  type="submit"
                  disabled={submitDisabled || submitting}
                  size="large"
                  backgroundColor={submitDisabled || submitting ? 'secondarylightBg' : 'secondary'}
                  style={{ cursor: 'pointer' }}
                >
                  <Text color="primary" fontSize={3} padding="5px" fontFamily="bold">
                    {submitting ? 'Booking...' : 'Book service'}
                  </Text>
                </Button>
              </>
            )}
          </form>
        </Box>
      </Flex>
    </ThemeProvider>
  );
};

export default Services;
