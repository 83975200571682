import React, { useState } from 'react';
import Services from '../components/services';
import SEO from '../components/seo';
import { Box, Flex } from 'rebass/styled-components';
import * as queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { avicennaTheme } from '../theme/GlobalStyle';
import { PageProps } from 'gatsby';
import ServicesMap from '../components/servicesMap';
import { ServicesContext } from '../contexts/ServicesContext';

const ServicesIframe = ({ location }: PageProps) => {
  const [selectedPharmacyId, setSelectedPharmacyId] = useState<string>('');
  const [selectedPharmacyApiKey, setSelectedPharmacyApiKey] = useState<string>('');

  let { service, pharmacy, labelColour, owned, platform } = queryString.parse(location.search);
  if(Array.isArray(service)) service = service.toString(); //to remove type error
  if(Array.isArray(pharmacy)) pharmacy = pharmacy.toString();
  labelColour = labelColour ?? 'black';

  return (
    <>
      <SEO title="Book a Pharmacy Service" />
      <ThemeProvider theme={avicennaTheme}>
        <Flex flexWrap="wrap">
          <ServicesContext.Provider
            value={{
              selectedPharmacyApiKey,
              setSelectedPharmacyApiKey,
              selectedPharmacyId,
              setSelectedPharmacyId,
            }}
          >
            <Box pr={3} py={3} width={[1, 1 / 2, 1 / 2]}>
              <ServicesMap labelColour={labelColour} pharmacy={pharmacy} owned={owned} showSearch={true} />
            </Box>
            <Box p={3} width={[1, 1 / 2, 1 / 2]}>
              {selectedPharmacyId && selectedPharmacyApiKey && (
                <Services labelColour={labelColour} platform={platform} initialService={service} />
              )}
            </Box>
          </ServicesContext.Provider>
        </Flex>
      </ThemeProvider>
    </>
  );
};

export default ServicesIframe;
